<template>
  <div class="ybmxm">
    <!-- 发起流转审批弹窗 -->

    <div class="project">
      <div style="padding: 0 30px">
        <h3>流程配置</h3>
      </div>
      <div class="table-card">
        <div class="table-con">
          <el-table
            border
            class="table"
            :data="tableData"
            style="width: 100%"
            :row-class-name="handleRowClass"
          >
            <el-table-column
              align="center"
              prop="xmbh"
              label="流程ID"
              width="200px"
            ></el-table-column>

            <el-table-column
              align="center"
              prop="jyfs"
              label="流程名称"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="zcmj"
              label="版本"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="lznx"
              label="发布时间"
            ></el-table-column>
            <el-table-column
              align="center"
              prop="gpjg"
              label="更新时间"
            ></el-table-column>
            <el-table-column align="center" label="操作" width="200">
              <template slot-scope="scope">
                <el-button
                  style="height: 28px; padding: 0 8px"
                  type="primary"
                  @click="noSelect(scope.row)"
                  >配置</el-button
                >
              </template>
            </el-table-column>
          </el-table>
        </div>
        <div class="pagenation">
          <el-pagination
            background
            layout="total, prev, pager, next"
            :current-page="currentPage"
            :page-size="pageSize"
            :total="total"
            @current-change="currentChange"
          >
          </el-pagination>
        </div>
      </div>
    </div>
    <el-dialog
      title="设置流程节点负责人"
      :visible.sync="dialogVisible"
      width="90%"
    >
      <div class="lcmc">
        <div><span>流程名称:</span>资源资产流转审批</div>
        <div><span>流程ID:</span>16545654654</div>
      </div>
      <div class="pzlct">
        <div class="yuan">村集体</div>
        <div class="arrow-container">
          <div class="vertical-line"></div>
          <!-- 竖杠 -->
          <div class="arrow-down"></div>
          <!-- 向下的箭头 -->
        </div>

        <!-- 添加审批人 -->
        <div v-for="index in 3" key="index">
          <div class="zheng">
            经办人(晚风)
            <sapn
              style="color: #51a9dd; margin-left: 10px"
              @click="dialogVisible1 = true"
              >设置</sapn
            >
          </div>
          <div class="arrow-container">
            <div class="vertical-line">
              <el-button class="but" icon="el-icon-plus">添加审批人</el-button>
              <!-- <buttton ></buttton> -->
            </div>
            <!-- 竖杠 -->
            <div class="arrow-down"></div>
            <!-- 向下的箭头 -->
          </div>
        </div>

        <div class="yuan">区政府</div>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button @click="clearSelection()">关闭</el-button>
        <el-button type="primary" @click="dialogVisible = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>

    <el-dialog
      :visible.sync="dialogVisible1"
      width="90%"
      modal="false"
      title="配置"
      style="z-index: 9999999"
    >
      <div>
        <table class="tab">
          <tr>
            <td>流程节点名称</td>
            <td>办理人角色</td>
            <td>办理人角色</td>
          </tr>
          <tr>
            <td>审批节点</td>
            <td>
              <el-input
                style="width: 200px"
                v-model="form.name"
                autocomplete="off"
              ></el-input>
            </td>
            <td>
              <el-select v-model="form.region" placeholder="请选择活动区域">
                <el-option label="区域一" value="shanghai"></el-option>
                <el-option label="区域二" value="beijing"></el-option>
              </el-select>
            </td>
          </tr>
        </table>
      </div>
      <span slot="footer" class="dialog-footer">
        <el-button @click="dialogVisible1 = false">关闭</el-button>
        <el-button type="primary" @click="dialogVisible1 = false"
          >确 定</el-button
        >
      </span>
    </el-dialog>
  </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
  components: {},
  data() {
    return {
      form: {
        name: "wwwww",
        region: "",
      },
      tableData: [
        {
          xmbh: "1454545",
          gpjg: "23:56:52",
          lznx: "25:85",
          zcmj: "v1.2",
          jyfs: "wxxsws",
        },
      ],
      dialogVisible: false,
      dialogVisible1: false,
      props: {
        label: "areaName",
        children: "children",
        value: "id",
        fullPathName: "fullPathName",
        checkStrictly: true,
        emitPath: false,
      },

      currentPage: 1,
      pageSize: 10,
      total: 0,
    };
  },
  computed: mapGetters(["userInfo", "identityObj"]),
  created() {
    // this.initData();
  },
  methods: {
    noSelect(row) {
      this.dialogVisible = true;
    },
    handleClose(done) {
      this.$confirm("确认关闭？")
        .then((_) => {
          done();
        })
        .catch((_) => {});
    },

    toggleSelection(rows) {
      if (rows) {
        rows.forEach((row) => {
          this.$refs.multipleTable.toggleRowSelection(row);
        });
      } else {
        this.$refs.multipleTable.clearSelection();
      }
    },

    async initData() {
      // 获取数据
      this.tableData = [];
      let params = {};
      let res = await selectCollectionTrade(params);
      let { code, data, message } = res;
      if (data) {
        this.tableData = data.records;
        console.log("tableData", this.tableData);
        this.total = parseInt(data.total);
      }
    },

    // 切换页码
    currentChange(val) {
      this.currentPage = val;
      this.initData();
    },

    handleRowClass({ row, rowIndex }) {
      // 行樣式
      if (rowIndex % 2 === 0) {
        return "odd";
      }
    },
  },
};
</script>

<style lang="less" scoped>
.tab {
  width: 100%;
  border: 1px solid #000;
  border-collapse: collapse; /* 合并边框 */
  text-align: center;
  td {
    width: 30%;
    height: 50px;
    border: 1px solid #000;
    padding: 0; /* 取消内边距 */
    margin: 0; /* 取消外边距 */
  }
}
.arrow-container {
  display: flex;
  flex-direction: column;
  align-items: center; /* 居中对齐 */
  margin: 20px; /* 添加一些外边距 */
}
.vertical-line {
  position: relative;
  width: 4px; /* 竖杠的宽度 */
  height: 40px; /* 竖杠的高度 */
  background-color: black; /* 竖杠颜色 */
  .but {
    position: absolute;
    width: 200px; /* 竖杠的宽度 */
    height: 40px; /* 竖杠的高度 */
    font-size: 20px;
    color: #00a0e9;
    left: 20px;
  }
}
.arrow-down {
  width: 0;
  height: 0;
  border-left: 10px solid transparent; /* 左边透明边框 */
  border-right: 10px solid transparent; /* 右边透明边框 */
  border-top: 10px solid black; /* 上边黑色边框 */
}
.pzlct {
  margin-top: 20px;
  display: flex;
  align-items: center; /* 垂直居中 */
  flex-direction: column; /* 垂直排列 */
  justify-content: space-between; /* 上下分布 */
  text-align: center;
}
.lcmc {
  display: flex;
  font-size: 18px;
  span {
    font-size: 20px;
  }
  div {
    margin-left: 50px;
  }
}
.yuan {
  width: 200px;
  height: 100px;
  border: 1px solid #000;
  border-radius: 50%;
  line-height: 100px;
}
.zheng {
  width: 250px;
  height: 100px;
  border: 1px solid #000;
  border-radius: 15px;
  line-height: 100px;
}
.ybmxm {
  text-align: left;
  height: 100%;
  position: relative;
  // margin-top: 46px;

  .s-title {
    .title {
      margin-left: 10px;
      font-size: 15px;
      font-weight: 600;
    }
  }
  .project {
    // margin-top: 38px;
    .table-con {
      margin-top: 12px;
      padding: 0 15px 0 18px;
    }
  }
  .news {
    margin-top: 33px;
  }
  .pagenation {
    // margin-top: 0;
    // position: absolute;
    // right: 20px;
    // bottom: 33px;
    text-align: right;
  }
}
.query-form {
  display: flex;
  .el-form-item {
    display: flex;
    align-items: center;
    .el-form-item__content {
      width: 100px;
    }
  }
  .query-btn {
    width: 70px;
  }
}
</style>

<style lang="less">
.ybmxm {
  .el-dialog__wrapper {
    position: absolute;
  }
  .v-modal {
    position: absolute;
  }
  .el-checkbox-button__inner {
    width: 160px;
    border-left: 1px solid #dcdfe6;
  }
  .el-dialog--center .el-dialog__body {
    padding: 25px 60px 30px 38px;
  }
  .el-checkbox-button.is-checked .el-checkbox-button__inner {
    border-radius: 5px;
    border: solid 1px #00a0e9;
    background: #fff;
    color: #00a0e9;
  }
  .el-dialog__footer {
    padding: 10px 20px 45px;
  }
}
.disUoloadSty .el-upload--picture-card {
  display: none; /* 上传按钮隐藏 */
}
</style>
